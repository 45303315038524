import React, {Component} from 'react';

export function onChange(target, value){
  console.log("onChange target value", target, value)
  this.setState({[target]: value})
  console.log("this.state", this.state)
}

export function renderTextInput(name, stateValue, secure = false){
  const value = this.state[stateValue] || '';
  const {container} = {position: 'relative'};
  const {inputBox} = {width: 300 + 'px', color: 'black'};
  
  return (
    <div style={container}>
    
    <form>
        value={value}
        name={name}
        {/*secureTextEntry={secure}*/}
        <textarea value={this.state.value} onChange={this.onChange} />
        style={inputBox}
    </form>
      
    
    {/*
      <TextInput
        value={value}
        name={name}
        secureTextEntry={secure}
        onChangeText={(value) => this.onChange(name, value)}
        style={inputBox}
      />
    */} 
      
    </div>
    
    
  );
}


