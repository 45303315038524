import React, {Component} from 'react'
import {compose} from 'redux';
import {connect} from "react-redux"
import { Dispatcher, View, Action, Input } from 'react-router-flux';
//import {Actions} from 'react-native-router-flux';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import axios from 'axios'
import _ from 'lodash'

import Loader from "./Loader";
import Logo from './Logo';

import {renderTextInput, onChange} from './inputs'
import {loginUser, loginUserToy} from './user'
import LoginForm from './LoginForm'



class Login extends Component {
  constructor(props){
    super(props)

    this.renderTextInput = renderTextInput.bind(this)
    this.onChange = onChange.bind(this)
  }
  
  state = {
    username: '',
    email: '',
    password: '',
    id: null,
    user_id: null,
    //toy_id: null,


    loading: false,
    touched: false
  }
  
  
	toggleTouched = () => {
		this.setState( prevState => ({
			touched: !prevState.touched
		}));
	}
	
	handleMouseUp = () => {
		setTimeout( () => {
			this.setState({ touched: false });
		}, 150);
	}

/*
	componentDidMount() {
		if (this.props.user && this.props.user.token) {        //Do I Need This??? 
			setTimeout(() => Action.profile(), 0)
			console.log('User is logged in')
		}
	}
*/
  	

  

  onSubmit = async (values) => {
    this.setState({loading: true})
    //Add validate if you want
    //Prevent from submitting if errors
    try{

      const {email, password, user_id, id} = this.state

      await this.props.loginUser(email, password)
      //this.toProfilePage()                                        //Do I Need This???
      console.log('LOGIN loginUser email', email)
      console.log('LOGIN loginUser password', password)


      await this.props.loginUserToy(email, password, id, user_id)
      console.log('LOGIN loginUserToy email', email)
      console.log('LOGIN loginUserToy password', password)
      console.log('LOGIN loginUserToy id', id)
      console.log('LOGIN loginUserToy user_id', user_id)

      //<Redirect to='/api/user_profile' />
      this.props.history.push('/api/user_profile')

      return //We return cause we change page and don't want to throw
    } catch(err){
      console.log("Exception in Login.onSubmit err", err)
    }
    console.log("Login.onSubmit this.props.user", this.props.user)
    console.log("Login.onSubmit this.props.toy", this.props.toy)
    this.setState({loading: false})
  }



	render() {
    const {user, toy, history} = this.props
    const { loading, touched } = this.state
    {/*const {loading} = this.state;*/} 

    const className = touched? 'btn touched': 'btn';
    const littleclassName = touched? 'btn touched': 'btn';
    const body = {backgroundColor: '#455a64'};
    const container = {marginLeft: 300 + 'px', height: 1000 + 'px'};
    const logo = {marginLeft: 150 + 'px'}
    const placeholderText = {color: 'white', size: 12 +'px', marginLeft: 100 + 'px'}
    const signupText = {color: 'white', size: 14 + 'px', marginLeft: 100 + 'px', paddingTop: 20 + 'px'}
    const button = {marginTop: 50 + 'px', background: '#1c313a', borderRadius: 30 + 'px', marginLeft: 200 + 'px', width: 200}
    const buttonText = {size:16, color:'white', font: 'bold'}
    const littlebutton = {marginTop: 10 + 'px', background: '#1c313a', borderRadius: 30 + 'px', marginLeft: 200 + 'px', width: 200}
    const littlebuttonText = {size:16, color:'white', font: 'bold'}
    const form = {paddingTop: 50 + 'px', marginLeft: 150 + 'px'}
    const textInput = {textIndent: 99999 + 'px'}
    
    
    console.log("Login.Render user", user)
    console.log("Login.Render toy", toy)

		return(
		
			<div style={body}>
			<div style={container}>
        {/*{(touched) && <Loader />}*/}
        {(loading) && <Loader />}
        		{/*<Loader />*/}
        		
			<div style={logo}>
				<Logo />
				</div>
				
        {user && user.token &&
          <p>
            User is Logged in
          </p>
        }
        
        
        {/*
        <div style={form}>
        
        <div style={textInput}>
        {this.renderTextInput('email', 'email', false)}
        </div>
        <p style={placeholderText}>Email</p>
        
        <div style={textInput}>
        {this.renderTextInput('password', 'password', true)}
        </div>
        <p style={placeholderText}>Password</p>

        </div>
        */} 


        <LoginForm onSubmit={this.onSubmit} />


        {/* Login Button */}
        <button
			className={className}
			style={button}
			onMouseDown={this.toggleTouched}
			onMouseUp={this.handleMouseUp}
			onClick={this.onSubmit}>
			<p style={buttonText}>Login</p>
		</button>
		
		




        {/* Signup Link */}
				<div style={signupText}>
					<p style={signupText}>Do not have an account yet?</p>
				</div>
					
			<Link to="/api/create_user">
			     {/*className={className}*/}
	       		 <button style={littlebutton}
	       		 onMouseDown={this.toggleTouched}
				 onMouseUp={this.handleMouseUp}
				 >
				 <p style={littlebuttonText}>Signup</p>
	       		 </button>
	       	</Link>
					
			</div>
			</div>
			)
	}
}




const mapStateToProps = (state) => ({
  user: state.user,
  toy: state.toy
});


export default connect(mapStateToProps, {loginUser, loginUserToy})(Login);




