import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Login from './Login';
import Register from './Register';
import Profile from './Profile';
import Toy from './Toy';
import EditProfile from './editProfile';
import EditToy from './editToy';

class Routes extends Component {

	render() {
	return (
	  <Router>	   
	     <Switch>
	      {/*<Route path='/' exact component={Login} />*/}
	      <Route path='/api/login' exact component={Login} />
	      <Route path='/api/create_user' exact component={Register} />
	      <Route path='/api/user_profile' exact component={Profile} />
	      <Route path='/api/toy' exact component={Toy} />
	      <Route path='/api/edit_user_profile' exact component={EditProfile} />
	      <Route path='/api/edit_toy' exact component={EditToy} />
	      <Route component={NotFound} />
	     </Switch>
	   </Router>
   );
  }
}

export default Routes;

const NotFound = () => <div>Page not found</div>;