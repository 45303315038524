import {combineReducers} from 'redux'
import { reducer as formReducer } from 'redux-form'
import {user_reducer, toy_reducer} from './reducer_user'

const rootReducer = combineReducers({
  user: user_reducer,
  toy: toy_reducer,
  form: formReducer
});

export default rootReducer;
