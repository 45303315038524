import React, {Component} from 'react';
import {connect} from 'react-redux';
//import {Actions} from 'react-native-router-flux';
import { Dispatcher, View, Action, Input } from 'react-router-flux';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import axios from 'axios';
import UploadScreen from './UploadScreen';
import Logo from './Logo';
import Loader from './Loader';


import {logoutUser, logoutUserToy} from './user';
import {renderTextInput, onChange} from './inputs';



class Toy extends Component {

  constructor(props){
  super(props)

  this.renderTextInput = renderTextInput.bind(this)
  this.onChange = onChange.bind(this)
    //this.validate = validate.bind(this)
  }
  
    state = {
    	email: '',
   		password: '',
    	user_id: null,
    	name: '',
    	description: '',
    	location: '',
    	//date: new Date(),
   	 	date: '',
    	picture: '',

    	image: false
  	}


	logoutUser = async () => {
    	await this.props.logoutUser()
	}




	render() {
    const { user, toy } = this.props;
    const container = {position: 'relative'};
    const textStyle = {color: 'black', size: 16 +'px'};
    const body = {backgroundColor: '#455a64'};
    
		return(
			<div style={body}>
			<div style={container}>
        {/* User is logged in */}
        {user.token &&
          <>
            <p style={textStyle}>This is the toy page for {user.email}
                {"\n"}
                {"\n"}
                Name: {toy.name}
                {"\n"}
                {"\n"}
                Description: {toy.description}
                {"\n"}
                {"\n"}
                Where: {toy.location}
                {"\n"}
                {"\n"}
                When: {toy.date}
                {"\n"}
                {"\n"}
                Picture: {toy.image}
            </p>

          </>
        }

			</div>
			</div>
			)
	}
}



const mapStateToProps = (state) => ({
    user: state.user,
    toy: state.toy
});



export default connect(mapStateToProps, {logoutUserToy})(Toy);


{/*export default Toy;*/}


