//import {FETCH_USER, LOGOUT_USER} from '../actions/user'
import {FETCH_USER, FETCH_TOY, LOGOUT_USER, LOGOUT_TOY, SIGNUP_USER, SIGNUP_TOY} from './user'



export const user_reducer = (state = {}, action) => {

  console.log("user_reducer state, action", state, action)

  switch (action.type) {

    case FETCH_USER:
      if(action.payload){
        return action.payload
      }


    case SIGNUP_USER:                                                       // Do I want signup_user & signup_toy action types ? 
      if(action.payload){
        return action.payload
      }

    
    case 'persist/REHYDRATE':                                              // Does this just mean something fucked up ? 
      console.log("THIS IS IT DAMN USER_REDUCER", action)
      if(action.err == undefined){
        return state //Just return the normal stuff
      } else {
        return {} //We remove all malicious / not working data
      }

    case LOGOUT_USER:
      return action.payload
      
    default:
      return state
  }
}



export const toy_reducer = (state = {}, action) => {

  console.log("toy_reducer state, action", state, action)

  switch (action.type) {

    case FETCH_TOY:
      if(action.payload){
        return action.payload
      }


    case SIGNUP_TOY:
      if(action.payload){
        return action.payload
      }


    case 'persist/REHYDRATE':
      console.log("THIS IS IT DAMN TOY_REDUCER", action)
      if(action.err == undefined){
        return state //Just return the normal stuff
      } else {
        return {} //We remove all malicious / not working data
      }
      
    case LOGOUT_TOY:
      return action.payload

    default:
      return state

  }
}


