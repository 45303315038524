import axios from 'axios'         //http client
import thunk from 'redux-thunk' 
import promise from 'redux-promise'

import {API_URL} from './constants'

export const FETCH_USER = 'fetch_user' 
export const LOGOUT_USER = 'logout_user'
export const FETCH_TOY = 'fetch_toy'
export const LOGOUT_TOY = 'logout_toy'

export const SIGNUP_USER = 'signup_user'
export const SIGNUP_TOY = 'signup_toy'




export const signupUser = async (username, email, password) => {
//export function signupUser(username, email, password) {
//export const signupUser(username, email, password) {

  return function action(dispatch) {
    //return async (dispatch) => { 


  try {


  dispatch({ type: SIGNUP_USER }) 


  console.log("signupUser username", username)
  console.log("signupUser email", email)
  console.log("signupUser password", password)

  

  const response = axios({
    method: 'POST',
    responseType: 'json', 
    url: `${API_URL}/api/create_user`,
    data: {
      username,
      email,
      password
    }
  })

  console.log('signupUser response', response)



  const newResponse = axios({
      method: 'POST',
      url: `${API_URL}/api/get_token`,
      data: {
        email,
        password
      }
    })

  console.log('signupUser newResponse', newResponse)

  console.log('signupUser token', token)


  const {token} = newResponse.data 


  dispatch({ type: FETCH_USER })


  const userResponse = axios({
      method: 'POST',
      url: `${API_URL}/api/login`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        email,
        password
      }
    })

    console.log("signupUser userResponse", userResponse)



  const getProfileResponse = axios({
      method: 'POST',
      //url: `${API_URL}/api/user_profile`,
      url: `${API_URL}/api/user_profile`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        email
      }
    })

    console.log('signupUser getProfileResponse', getProfileResponse)

    const {Avatar, Location, Name} = getProfileResponse.data
    const aboutMe = getProfileResponse.data['About Me']
    const memberSince = getProfileResponse.data['Member Since']


    return (
      {

        //type: SIGNUP_USER,                   // Do I Include Type Here ? 
        payload: {
          token,
          username,
          email,
          password,
          name: Name,
          location: Location,
          aboutMe,
          memberSince,
          picture: Avatar
        }
      }
    )
    } catch(err){
    console.log("Exception in actions/user/signupUser err", err)
  }
}
}



/*
export const signupUser = async (username, email, password) => {

  console.log("signupUser username", username)
  console.log("signupUser email", email)
  console.log("signupUser password", password)

  try{

  const response = await axios({
    method: 'POST',
    url: `${API_URL}/api/create_user`,
    data: {
      username,
      email,
      password
    }
  })

  console.log('signupUser response', response)



  const newResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/get_token`,
      data: {
        email,
        password
      }
    })

    console.log('signupUser newResponse', newResponse)

  const {token} = response.data
  const userResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/login`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        email,
        password
      }
    })

    console.log("signupUser userResponse", userResponse)



  const getProfileResponse = await axios({
      method: 'POST',
      //url: `${API_URL}/api/user_profile`,
      url: `${API_URL}/api/user_profile`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        email
      }
    })

    console.log('signupUser getProfileResponse', getProfileResponse)

    const {Avatar, Location, Name} = getProfileResponse.data
    const aboutMe = getProfileResponse.data['About Me']
    const memberSince = getProfileResponse.data['Member Since']




    return (
      {

        type: SIGNUP_USER,
        payload: {
          token,
          username,
          email,
          password,
          name: Name,
          location: Location,
          aboutMe,
          memberSince,
          picture: Avatar
        }
      }
    )
    } catch(err){
    console.log("Exception in actions/user/signupUser err", err)
  }
}
*/


export const signupUserToy = async (username, email, password, id, user_id) => {
  console.log("signupUser username", username)
  console.log("signupUser email", email)
  console.log("signupUser password", password)

  const response = await axios({
    method: 'POST',
    url: `${API_URL}/api/create_user`,
    data: {
      username,
      email,
      password
    }
  })

   const newResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/get_token`,
      data: {
        email,
        password
      }
    })
    console.log("getToken response.status", newResponse.status)

    const {token} = newResponse.data

    const userResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/login`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        email,
        password
      }
    })
    console.log("userResponse.data", userResponse.data)


  const getProfileResponse = await axios({
      method: 'POST',
      //url: `${API_URL}/api/user_profile`,
      url: `${API_URL}/api/toy`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        //toy_id
        id,
        user_id
      }
    })

    console.log('getProfileResponse.data', getProfileResponse.data)
    //const {Name, Description, Where, When, Picture} = getProfileResponse.data
    const {Name, Description, Location, Time, Avatar_Hash} = getProfileResponse.data


    return (
      {

        type: SIGNUP_TOY,
        payload: {
          token,
          username,
          email,
          password,
          id,
          user_id,
          //toy_id,
          name: Name,
          description: Description,
          //location: Where,
          location: Location,
          //date: When,
          time: Time, 
          //image: Picture,
          avatar_hash: Avatar_Hash
        }
      }
    )
  }





export const loginUser = async (email, password) => {

  console.log("loginUser email", email)
  console.log("loginUser password", password)

  try{
    const response = await axios({
      method: 'POST',
      url: `${API_URL}/api/get_token`,
      data: {
        email,
        password
      }
    })
    console.log('loginUser response', response)

    const {token} = response.data
    console.log('loginUser token', token)

    const userResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/login`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        email,
        password
      }
    })

    console.log('loginUser userResponse', userResponse)

    const getProfileResponse = await axios({
      method: 'POST',
      //url: `${API_URL}/api/user_profile`,
      url: `${API_URL}/api/user_profile`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        email
      }
    })

    console.log('loginUser getProfileResponse', getProfileResponse)

    const {Avatar, Location, Name} = getProfileResponse.data
    const aboutMe = getProfileResponse.data['About Me']
    const memberSince = getProfileResponse.data['Member Since']


    return (
      {
        type: FETCH_USER,
        payload: {
          token,
          password,
          email,
          name: Name,
          location: Location,
          aboutMe,
          memberSince,
          picture: Avatar
        }
      }
    )

  } catch(err){
    console.log("Exception in actions/user/loginUser err", err)
  }
}




export const loginUserToy = async (email, password, id, user_id) => {
//export const loginUserToy = async (email, password, id, toy_id) => {
  try{
    const response = await axios({
      method: 'POST',
      url: `${API_URL}/api/get_token`,
      data: {
        email,
        password
      }
    })

    const {token} = response.data
    const userResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/login`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        email,
        password
      }
    })


    const getProfileResponse = await axios({
      method: 'POST',
      //url: `${API_URL}/api/user_profile`,
      url: `${API_URL}/api/toy`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        id,
        //toy_id
        user_id
      }
    })

    const {Name, Description, Location, Time, Avatar_Hash} = getProfileResponse.data


    return (
      {
        type: FETCH_TOY,
        payload: {
          token,
          email,
          password,
          id,
          user_id,
          //toy_id,
          name: Name,
          description: Description,
          //location: Where,
          location: Location,
          //date: When,
          time: Time,
          //image: Picture
          avatar_hash: Avatar_Hash
        }
      }
    )

  } catch(err){
    console.log("Exception in actions/user/loginUser err", err)
  }
}


// Remove dispatch to basic return

export const logoutUser = async () => {
  const response = await axios({
    method: 'POST',
    url: `${API_URL}/api/logout`,
    headers: {

    }
  })
  console.log("logoutUser response", response)
  return (
    {
      type: LOGOUT_USER,
      //type: FETCH_USER,
      payload: {}

    }
  )
}



export const logoutUserToy = async () => {
  const response = await axios({
    method: 'POST',
    url: `${API_URL}/api/logout`,
    headers: {
    }
  })
  console.log("logoutUserToy response", response)
  return (
    {
      type: LOGOUT_TOY,
      //type: FETCH_TOY,
      payload: {}
    }
  )
}





export const editProfileUser = async (email, password, name, location, aboutMe, memberSince, picture) => {
  console.log("editProfileUser name", name)
  console.log("editProfileUser location", location)
  console.log("editProfileUser aboutMe", aboutMe)
  console.log("editProfileUser memberSince", memberSince)
  console.log("editProfileUser picture", picture)
  console.log("editProfileUser email", email)
  console.log("editProfileUser password", password)
  try{
  const response = await axios({
    method: 'POST',
    url: `${API_URL}/api/get_token`,
    data: {
      email,
      password
    }
  })

  const {token} = response.data
  const userResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/edit_user_profile`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        email,
        my_name: name,
        my_location: location,
        about_me: aboutMe,
        member_since: memberSince,
        avatar_hash: picture
      }
    })
 
  console.log("userResponse.data", userResponse.data)

    return (
      {
        type: FETCH_USER,
        payload: {
          token,
          email,
          password,
          name,
          location,
          aboutMe,
          memberSince,
          picture
        }
      }
    )


  } catch(err){
    console.log("Exception in actions/user/editProfileUser err", err)
  }
}




//export const editToyUser = async (email, password, user_id, name, description, location, date, image) => {
export const editToyUser = async (email, password, user_id, name, description, location, time, avatar_hash) => {
  console.log("editToyUser name", name)
  console.log("editToyUser description", description)
  console.log("editToyUser location", location)
  //console.log("editToyUser date", date)
  //console.log("editToyUser image", image)
  console.log("editToyUser time", time)
  console.log("editToyUser avatar_hash", avatar_hash)
  console.log("editToyUser email", email)
  console.log("editToyUser password", password)
  try{
  const response = await axios({
    method: 'POST',
    url: `${API_URL}/api/get_token`,
    data: {
      email,
      password
    }
  })
  const {token} = response.data
  const userResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/edit_toy`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        user_id,
        name,
        description,
        location,
        time,
        avatar_hash
      }
    })
    console.log("userResponse.data", userResponse.data)
    return (
      {
        type: FETCH_TOY,
        payload: {
          email,
          password,
          token,
          user_id,
          name,
          description,
          location,
          time,
          avatar_hash
        }
      }
    )

  } catch(err){
    console.log("Exception in actions/user/editToy err", err)
  }
}




/*
export const forgot = async (email) => {

  try{

    
    const response = await axios({
      method: 'POST',
      url: `${API_URL}/api/get_token_again`,
      data: {
        email,
      }
    })
    const {token} = response.data
    console.log("forgot ResponseData", response.data)
    

    const userResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/forgot`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        email,
      }
    })

    console.log("forgot userResponseData", userResponse.data) 

    return (
      {
        type: FETCH_USER,
        payload: {
          token,
          email
        }
      }
    )

  } catch(err){
    console.log("Exception in actions/user/forgot err", err)
  }
}
*/


export const forgot = async (email) => {

  try{
    
    const userResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/forgot`,
      data: {
        email,
      }
    })

    console.log("forgot userResponseData", userResponse.data) 

    return (
      {
        type: FETCH_USER,
        payload: {
          email
        }
      }
    )

  } catch(err){
    console.log("Exception in actions/user/forgot err", err)
  }
}




export const invite = async (email) => {

  try{


    const userResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/invite`,

      data: {
        email,
      }
    })

    console.log("invite userResponseData", userResponse.data) 

    return (
      {
        type: FETCH_USER,
        payload: {
          email
        }
      }
    )

  } catch(err){
    console.log("Exception in actions/user/invite err", err)
  }
}



/*
export const invite = async (email, password) => {

  try{

    const response = await axios({
      method: 'POST',
      url: `${API_URL}/api/get_token`,
      data: {
        email,
        password
      }
    })

    const {token} = response.data

    console.log("invite ResponseData", response.data)


    const userResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/login`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        email,
        password
      }
    })

    console.log("invite userResponseData", userResponse.data) 

    const inviteResponse = await axios({
      method: 'POST',
      url: `${API_URL}/api/invite`,
      headers: {
        Authorization: `${token}`
      },
      data: {
        email,
      }
    })

    console.log("invite inviteResponseData", inviteResponse.data) 

    return (
      {
        type: FETCH_USER,
        payload: {
          token,
          email,
          password
        }
      }
    )

  } catch(err){
    console.log("Exception in actions/user/invite err", err)
  }
}
*/





