import React, {Component} from 'react';
import {compose} from 'redux'; 
import {connect} from 'react-redux';
import { Dispatcher, View, Action, Input } from 'react-router-flux';
//import {Actions} from 'react-native-router-flux';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash'

import Logo from './Logo';
import Loader from './Loader';

import {signupUser, signupUserToy } from './user';
import {renderTextInput, onChange} from './inputs';

import RegisterForm from './RegisterForm'


class Register extends Component{
  constructor(props){
    super(props)

    this.renderTextInput = renderTextInput.bind(this)
    this.onChange = onChange.bind(this)
    //this.validate = validate.bind(this)
  }

  state = {
    username: '',
    email: '',
    password: '',
	  id: null,
    user_id: null,
    //toy_id: null,

    loading: false,
    touched: false
  }
  
	toggleTouched = () => {
		this.setState( prevState => ({
			touched: !prevState.touched
		}));
	}
	
	handleMouseUp = () => {
		setTimeout( () => {
			this.setState({ touched: false });
		}, 150);
	}
  
  /*
  componentDidMount() {
    if (this.props.user && this.props.user.token) {              //Do I Need This???  
      setTimeout(() => Action.profile(), 0)
      console.log('User is logged in')
    }
  }
  */
    
  

  onSubmit = async (values) => {
    //event.preventDefault()
    this.setState({loading: true})

    try{

        const {username, email, password, user_id, id} = this.state


      await this.props.signupUser(username, email, password)
      console.log('SIGNUP signupUser username', username)
      console.log('SIGNUP signupUser email', email)
      console.log('SIGNUP signupUser password', password)

      
      await this.props.signupUserToy(username, email, password, id, user_id)
      console.log('SIGNUP signupUserToy username', username)
      console.log('SIGNUP signupUserToy email', email)
      console.log('SIGNUP signupUserToy password', password)
      console.log('SIGNUP signupUserToy id', id)
      console.log('SIGNUP signupUserToy user_id', user_id)
      

      //this.toProfilePage()
      //<Redirect to='/api/user_profile' />
      this.props.history.push('/api/user_profile')

      return //We return here cause we don't want to throw and we are done
    } catch(err){
      console.log("Exception on Signup.onSubmit err", err)
    }
    console.log("Signup.onSubmit this.props.user", this.props.user)
    console.log("Signup.onSubmit this.props.toy", this.props.toy)
    this.setState({loading: false})
  }



	render() {
    const {user, toy, history} = this.props
    const { loading, touched } = this.state
    {/*const {username, email, password, loading, touched} = this.state*/}  

    const className = touched? 'btn touched': 'btn';
    const littleclassName = touched? 'btn touched': 'btn';
    const body = {backgroundColor: '#455a64'};
    const container = {marginLeft: 300 + 'px', height: 1000 + 'px'};
    const logo = {marginLeft: 150 + 'px'}
    const placeholderText = {color: 'white', size: 12 +'px', marginLeft: 100 + 'px'}
    const signupText = {color: 'white', size: 14 + 'px', marginLeft: 100 + 'px', paddingTop: 20 + 'px'}
    const button = {marginTop: 50 + 'px', background: '#1c313a', borderRadius: 30 + 'px', marginLeft: 200 + 'px', width: 200}
    const buttonText = {size:16, color:'white', font: 'bold'}
    const littlebutton = {marginTop: 10 + 'px', background: '#1c313a', borderRadius: 30 + 'px', marginLeft: 200 + 'px', width: 200}
    const littlebuttonText = {size:16, color:'white', font: 'bold'}
    const signupButton = {color:'white', size: 16 + 'px'}
	  const form = {paddingTop: 50 + 'px', marginLeft: 150 + 'px'}
    const textInput = {textIndent: 99999 + 'px'}

    console.log("Signup.Render user", user)
    console.log("Signup.Render toy", toy)

		return(

		    <div style={body}>
			  <div style={container}>

        {/*{touched && <Loader />}*/}
        {(loading) && <Loader />}
        		{/*<Loader />*/}
        		
        		<div style={logo}>
				<Logo />
				</div>

        {user && user.token &&
          <p>
            User is Logged in
          </p>
        }


        {/*
        <div style={form}>
        
        <div style={textInput}>
        {this.renderTextInput('username', 'username', false)}
        </div>
        <p style={placeholderText}>Username</p>
        
        <div style={textInput}>
        {this.renderTextInput('email', 'email', false)}
        </div>
        <p style={placeholderText}>Email</p>
        
        <div style={textInput}>
        {this.renderTextInput('password', 'password', false)}
        </div>
        <p style={placeholderText}>Password</p>

        </div>
        */} 


        <RegisterForm onSubmit={this.onSubmit} />



        {/* Submit */}
        <button
			     className={className}
			     style={button}
			     onMouseDown={this.toggleTouched}
			     onMouseUp={this.handleMouseUp}
			     onClick={this.onSubmit}>
			     <p style={buttonText}>Signup</p>
		    </button>
        
        


        {/* Log in Link */}
				<div style={signupText}>
					<p style={signupText}>Already have an account?</p>
				</div>

					
			<Link to="/api/login">
			     {/*className={className}*/}
	       		 <button style={littlebutton}
	       		 onMouseDown={this.toggleTouched}
				     onMouseUp={this.handleMouseUp}
				     >
				     <p style={littlebuttonText}>Sign In</p>
	       		 </button>
	    </Link>
					

			</div>
			</div>
			)
	}
}



const mapStateToProps = (state) => ({
    user: state.user,
    toy: state.toy
});


export default connect(mapStateToProps, {signupUser, signupUserToy})(Register);









