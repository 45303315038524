import React, {Component} from 'react';
import {connect} from 'react-redux';
//import {Actions} from 'react-native-router-flux';
import { Dispatcher, View, Action, Input } from 'react-router-flux';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import axios from 'axios';
import UploadScreen from './UploadScreen';
import Logo from './Logo';
import Loader from './Loader';

import {renderTextInput, onChange} from './inputs';
import { editProfileUser, logoutUser} from './user';


class EditProfile extends Component {

  constructor(props){
  super(props)

  
  this.renderTextInput = renderTextInput.bind(this)
  this.onChange = onChange.bind(this)
  
  
  }



  state = {
    email: '',
    password: '',
    name: '',
    location: '',
    aboutMe: '',
    //memberSince: new Date(),
    memberSince: null,
    picture: null,

    loading: false,
    touched: false
  }


	toggleTouched = () => {
		this.setState( prevState => ({
			touched: !prevState.touched
		}));
	}
	
	handleMouseUp = () => {
		setTimeout( () => {
			this.setState({ touched: false });
		}, 150);
	}



/*
  componentDidMount() {
    if (this.props.user && this.props.user.token) {
      setTimeout(() => Action.editprofile(), 0)
      console.log('User is logged in')
    }
  }
*/ 


  logoutUser = async () => {
    	await this.props.logoutUser()
  }




  onSubmit = async (values) => {
    this.setState({loading: true})
    const {
      email,
      password,
      name,
      location,
      aboutMe,
      memberSince,
      picture
    } = this.state
    
    //Run validation here                                     # Removed from Web Version 
    //if (!email) email = this.props.user.email
    //if (!password) password = this.props.user.password
    //if (!name) name = this.props.user.name
    //if (!location) location = this.props.user.location
    //if (!aboutMe) aboutMe = this.props.user.aboutMe
    //if (!memberSince) memberSince = this.props.user.memberSince
    //if (!picture) picture = this.props.user.picture

    try{
      await this.props.editProfileUser(
        email,
        password,
        name,
        location,
        aboutMe,
        memberSince,
        picture
      )

      this.toProfilePage()
      return //We return here cause we don't want to throw and we are done
    } catch(err){
      console.log("Exception on submit err", err)
    }
    console.log("EditProfile.onSubmit this.props.user", this.props.user)
    this.setState({loading: false})
  }


	render() {
    const { user, toy } = this.props
    const { touched } = this.state
    const className = touched? 'btn touched': 'btn';
    const littleclassName = touched? 'btn touched': 'btn';
    const body = {backgroundColor: '#455a64'};
    const container = {marginLeft: 300 + 'px', height: 1000 + 'px'};
    const logo = {marginLeft: 150 + 'px'}
    const placeholderText = {color: 'white', size: 12 +'px', marginLeft: 100 + 'px'}
    const signupText = {color: 'white', size: 14 + 'px', marginLeft: 100 + 'px', paddingTop: 20 + 'px'}
    const button = {marginTop: 50 + 'px', background: '#1c313a', borderRadius: 30 + 'px', marginLeft: 200 + 'px', width: 200}
    const buttonText = {size:16, color:'white', font: 'bold'}
    const littlebutton = {marginTop: 10 + 'px', background: '#1c313a', borderRadius: 30 + 'px', marginLeft: 200 + 'px', width: 200}
    const littlebuttonText = {size:16, color:'white', font: 'bold'}
    const form = {paddingTop: 50 + 'px', marginLeft: 150 + 'px'}
    const signupButton = {color:'white', size: 16 + 'px'}
    const textStyle = {marginLeft: 300 + 'px', size:16, color:'white', font: 'bold'}
    
  
    
		return(
			<div style={body}>
			<div style={container}>
        {/*{(loading) && <Loader />}*/}
        		{/*<Loader />*/}
        {(touched) && <Loader />}

        {/* User is logged in */}
        {user.token &&
          <>
            <p style={textStyle}> Greetings, {user.email}</p>
            <p style={textStyle}> Edit your profile below</p>


              {/* Form */}
              {this.renderTextInput('name', 'name', false)}
              <p style={placeholderText}>Name</p>
              {this.renderTextInput('location', 'location', false)}
              <p style={placeholderText}>Location</p>
              {this.renderTextInput('aboutMe', 'aboutMe', false)}
              <p style={placeholderText}>Bio</p>
              {this.renderTextInput('memberSince', 'memberSince', false)}
              <p style={placeholderText}>Member Since</p>
              {this.renderTextInput('picture', 'picture', false)}
              <p style={placeholderText}>Picture</p>
              {/* END Form */}


            {/* Submit */}
            
            
              <button
			    className={className}
			    style={button}
			    onMouseDown={this.toggleTouched}
			    onMouseUp={this.handleMouseUp}
			    onClick={this.onSubmit}>
			    <p style={buttonText}>Save Changes</p>
			  </button>
            
            
			  
			<Link to="/api/user_profile">
			     {/*className={className}*/}
	       		 <button style={button}
	       		 onMouseDown={this.toggleTouched}
				 onMouseUp={this.handleMouseUp}
				 >
				 <p style={littlebuttonText}>Profile</p>
	       		 </button>
	       	</Link>

          </>
        }

			</div>
			</div>
			)
    }
  }



const mapStateToProps = (state) => ({
    user: state.user,
    toy: state.toy
});


export default connect(mapStateToProps, {editProfileUser})(EditProfile);


{/*export default EditProfile;*/}




