import React, {Component} from 'react';
import { render } from 'react-dom';
import { Spinner } from 'react-activity';
/* import 'react-activity/dis/react-activity.css'; */


class Loader extends Component{
  render() {
  const divStyle = {position: 'relative'}
  const spinStyle = {color: 'black', size: 10, speed: 1, animating: true};
   return (
    <div style={divStyle}>
      <div style = {spinStyle}>
    	<Spinner  />
      </div>
     </div>
    )
   }
}



export default Loader; 