import React, {Component} from 'react';
import {connect} from 'react-redux';
//import {Actions} from 'react-native-router-flux'; //Find Alternative
import { Dispatcher, View, Action, Input } from 'react-router-flux';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Img from 'react-image';
import axios from 'axios';
import UploadScreen from './UploadScreen';
import Logo from './Logo';
import Loader from './Loader';
import {logoutUser} from './user';
import {renderTextInput, onChange} from './inputs';

class Profile extends Component {

	constructor(props){
  	super(props)

  	this.renderTextInput = renderTextInput.bind(this)
  	this.onChange = onChange.bind(this)
    //this.validate = validate.bind(this)
  	}

	state = {
	    email: '',
   	 	password: '',
    	name: '',
    	location: '',
    	aboutMe: '',
    	//memberSince: new Date(),
    	memberSince: '',
    	picture: null,

		touched: false
	}
	
	toggleTouched = () => {
		this.setState( prevState => ({
			touched: !prevState.touched
		}));
	}
	
	handleMouseUp = () => {
		setTimeout( () => {
			this.setState({ touched: false });
		}, 150);
	}
	
	



  	logoutUser = async () => {
    	await this.props.logoutUser()
  	}


 	


	render() {
    const { user, toy } = this.props
    const { touched } = this.state
    const className = touched? 'btn touched': 'btn';
    const littleclassName = touched? 'btn touched': 'btn';
    const body = {backgroundColor: '#455a64'};
    const container = {marginLeft: 300 + 'px', height: 1000 + 'px'};
    const logo = {marginLeft: 150 + 'px'}
    const placeholderText = {color: 'white', size: 12 +'px', marginLeft: 100 + 'px'}
    const signupText = {color: 'white', size: 14 + 'px', marginLeft: 100 + 'px', paddingTop: 20 + 'px'}
    const button = {marginTop: 50 + 'px', background: '#1c313a', borderRadius: 30 + 'px', marginLeft: 200 + 'px', width: 200}
    const buttonText = {size:16, color:'white', font: 'bold'}
    const littlebutton = {marginTop: 10 + 'px', background: '#1c313a', borderRadius: 30 + 'px', marginLeft: 200 + 'px', width: 200}
    const littlebuttonText = {size:16, color:'white', font: 'bold'}
    const form = {paddingTop: 50 + 'px', marginLeft: 150 + 'px'}
    const signupButton = {color:'white', size: 16 + 'px'}
    const textStyle = {marginLeft: 300 + 'px', size:16, color:'white', font: 'bold'}
    const image = {width: 400 + 'px', height: 400 + 'px'}

    
    

		return(
			<div style={body}>
			<div style={container}>
        	{/* User is logged in */}
        	{user.token && 
         <>
            <p style={textStyle}>This is a profile page for {user.email} 
                {"\n"}
                {"\n"}
                Name: {user.name}
                {/*Name: {editProfileUser.name*/}
                {"\n"}
                {"\n"}
                Location: {user.location}
                {/*Location: {editProfileUser.location}*/}
                {"\n"}
                {"\n"}
                Bio: {user.aboutMe}
                {/*Bio: {editProfileUser.aboutMe}*/}
                {"\n"}
                {"\n"}
                Member Since: {user.memberSince}
                {/*Member Since: {editProfileUser.memberSince}*/}
                {"\n"}
                {"\n"}
                Avatar: {user.picture}
                {/*Avatar: {editProfileUser.picture}*/}
                {/*<Image style={image} source={{ uri: user.picture }} />*/}
                {/*<Img style={image} src={{ uri: user.picture }} />*/}
            </p>
				
			  
			  
			<Link to="/api/edit_user_profile">
			     {/*className={className}*/}
	       		 <button style={button}
	       		 onMouseDown={this.toggleTouched}
				 onMouseUp={this.handleMouseUp}
				 >
				 <p style={littlebuttonText}>Edit Profile</p>
	       		 </button>
	       	</Link>
			  
			  
			  
			<Link to="/api/edit_toy">
			     {/*className={className}*/}
	       		 <button style={button}
	       		 onMouseDown={this.toggleTouched}
				 onMouseUp={this.handleMouseUp}
				 >
				 <p style={littlebuttonText}>Edit Toy</p>
	       		 </button>
	       	</Link>
				
				
			  <button
			    className={className}
			    style={littlebutton}
			    onMouseDown={this.toggleTouched}
			    onMouseUp={this.handleMouseUp}
			    onClick={this.logoutUser}>
			    <p style={buttonText}>Logout</p>
			  </button>
				
          </>
        }


        {/* User is NOT logged in */}
        {!user.token &&
          <>
            <p style={textStyle}>You are not logged in</p>
            

			 <Link to="/api/create_user">
			     {/*className={className}*/}
	       		 <button style={button}
	       		 onMouseDown={this.toggleTouched}
				 onMouseUp={this.handleMouseUp}
				 >
				 <p style={littlebuttonText}>Signup</p>
	       		 </button>
	       	</Link>
	       	
	       	
	       	<Link to="/api/login">
			     {/*className={className}*/}
	       		 <button style={littlebutton}
	       		 onMouseDown={this.toggleTouched}
				 onMouseUp={this.handleMouseUp}
				 >
				 <p style={littlebuttonText}>Log In</p>
	       		 </button>
	       	</Link>
			  


          </>
        }


			</div>
			</div>
			)
	}
}



const mapStateToProps = (state) => ({
    user: state.user,
    toy: state.toy
});


export default connect(mapStateToProps, {logoutUser})(Profile);

{/*export default Profile;*/}




