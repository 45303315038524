import React, {Component} from 'react';
import {Provider} from 'react-redux'; // makes redux store available to any nested components wrapped inside connect()
import { PersistGate } from 'redux-persist/integration/react'; // delays rendering of app UI until persisted state retrieved and saved to redux

import App from './App';
import persist from './store';


const persistStore = persist();

export default class Main extends Component {      
	render() {
		console.log("App render")
		return (
			<Provider store={persistStore.store}>  
				<PersistGate loading={null} persistor={persistStore.persistor}>
					<App />
				</PersistGate>
			</Provider>
	 );
	}
}



