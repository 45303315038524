import React, {Component} from 'react';

class Logo extends Component {
	render() {
	const divStyle = {position: 'relative'};
	const imgStyle = {width: 300 + 'px', height: 300 + 'px', paddingTop: 20 + 'px'};
	const logoStyle = {color: "white", marginLeft: 100 + 'px'};
	
	 return(

	 <div style={divStyle}>
	  <img src={require('./images/logo.png')} style={imgStyle} />
	  <div style={logoStyle}>Welcome to hood. </div>
	  </div>
	  )
	}
}


export default Logo;

