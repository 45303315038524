//import { AsyncStorage } from 'react-native';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist' //redux persist is library allowing to save redux store in local storage in browser
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk' // middleware that extends store's abilities via async logic / allows action creators to return a FUNCTION
import promise from 'redux-promise' // also middleware w/ async ability / allows action creators to return a PROMISE 

//import reducers from './reducers';
import rootReducer from './reducer_index';
import user_reducer from './reducer_user';



const persistConfig = {
  key: 'root',
  //storage: AsyncStorage,
  storage: storage,
  whitelist: ["user", "toy", "form"]         // Form or No Form Here ??? 
  //whitelist: ["user", "toy"] 
}

//const persistedReducer = persistReducer(persistConfig, reducers)
//const persistedReducer = persistReducer(persistConfig, user_reducer)  // Do I need this one?
const persistedReducer = persistReducer(persistConfig, rootReducer)


export default () => {
  let store = createStore(persistedReducer, {}, applyMiddleware(thunk, promise)); // thunk allows action creators to return a function
  let persistor = persistStore(store)                                             // promise allows action creators to return a promise
  return { store, persistor }                                                     // both allow you to dispatch actions asynchronously 
}

