import React, {Component} from 'react';

import Routes from './Routes';


class App extends Component{
  render(){
    const container = {position: 'relative'};
  
    return(
      <div style={container}>
      
        {/*
        <StatusBar
         backgroundColor="#1c313a"
         barStyle="light-content"
         />
         */}
         
         <Routes />
      </div>
    )
  }
}



export default App;
